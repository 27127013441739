import { Controller } from "@hotwired/stimulus";
import IconPicker from "vanilla-icon-picker";

export default class extends Controller {
  static targets = ["input"];

  connect() {
    const input = this.inputTarget;

    const svgIcon = input.parentElement.querySelector(".svg-icon");
    svgIcon.onclick = () => input.click();
    new IconPicker(input, {
      iconSource: [
        {
          key: "tabler",
          prefix: "",
          url: "/tabler-icons.json",
        },
      ],
    }).on("select", (instance) => {
      svgIcon.innerHTML = instance.svg;
      // svgIcon.querySelector('svg').classList.add('text-primary-500');

      if (input.name.includes("design_setting[account_buttons_attributes]")) {
        input.parentElement.parentElement.parentElement.querySelector("[x-html='icon']").innerHTML =
          instance.svg;
      }
    });
  }
}
