import { Controller } from "@hotwired/stimulus";
import TomSelect from "tom-select";

export default class extends Controller {
  static targets = ["select"];
  static values = { options: Array };

  connect() {
    new TomSelect(this.selectTarget, {
      valueField: "id",
      searchField: "title",
      maxItems: 1,
      options: this.optionsValue,
      maxOptions: 99999999,
      render: {
        option: function (data, escape) {
          return `<span class="${escape(data.classes)} block">${escape(data.title)}</span>`;
        },
        item: function (data, escape) {
          return `<span class="${escape(data.classes)} block">${escape(data.title)}</span>`;
        },
      },
    });
  }
}
