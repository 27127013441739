window.searchAccountsController = function (accounts) {
  return {
    accounts,
    search: "",
    get filteredAccounts() {
      if (this.search === "") return this.accounts;

      return this.accounts.filter((account) => {
        return account.name
          .replace(/ /g, "")
          .toLowerCase()
          .includes(this.search.replace(/ /g, "").toLowerCase());
      });
    },
  };
};
