import { Controller } from "@hotwired/stimulus";
import Pickr from "@simonwep/pickr";

export default class extends Controller {
  static targets = ["element", "input", "clear"];

  connect() {
    const input = this.inputTarget;
    const clear_button = this.clearTarget;

    clear_button.addEventListener("click", function (e) {
      const color = "#e5e7eb";
      input.value = color;
      input.parentElement.querySelector(
        ".pcr-button"
      ).style = `transition: none 0s ease 0s; --pcr-color:${color};`;

      e.preventDefault();
    });

    Pickr.create({
      el: this.elementTarget,
      theme: "nano",
      comparison: false,
      default: input.value,
      useAsButton: false,
      swatches: [
        "rgba(0, 0, 0, 0.0)",
        "rgb(245, 246, 250)",
        "rgb(220, 221, 225)",
        "rgb(127, 143, 166)",
        "rgb(113, 128, 147)",
        "rgb(53, 59, 72)",
        "rgb(47, 54, 64)",
        "rgb(25, 42, 86)",
        "rgb(244, 67, 54)",
        "rgb(233, 30, 99)",
        "rgb(156, 39, 176)",
        "rgb(103, 58, 183)",
        "rgb(63, 81, 181)",
        "rgb(33, 150, 243)",
        "rgb(3, 169, 244)",
        "rgb(0, 188, 212)",
        "rgb(0, 150, 136)",
        "rgb(76, 175, 80)",
        "rgb(139, 195, 74)",
        "rgb(205, 220, 57)",
        "rgb(255, 235, 59)",
        "rgb(255, 193, 7)",
      ],
      components: {
        preview: true,
        opacity: false,
        hue: false,
        interaction: {
          hex: false,
          rgba: false,
          hsla: false,
          hsva: false,
          cmyk: false,
          input: true,
          clear: false,
          save: false,
        },
      },
    }).on("change", (color) => {
      input.value = color.toHEXA();

      if (input.name.includes("design_setting[account_buttons_attributes]")) {
        input.parentElement.parentElement.parentElement.querySelector(
          ".account-button-btn"
        ).style.backgroundColor = color.toHEXA();
      }

      if (input.classList.contains("has-apply-all-link")) {
        input.parentElement.parentElement.querySelector(
          ".apply-all"
        ).href = `/backoffice/button_settings/apply_all?column_name=${
          input.dataset.attributeName
        }&column_value=${color.toHEXA().toString().replaceAll("#", "")}`;
      }
    });
  }
}
