import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    frame: String,
  };

  submitEnd(e) {
    if (e.detail.success) {
      this.close();
    }
  }

  close() {
    const element = this.element;
    element.removeAttribute("reloadable");
    element.removeAttribute("src");
    element.parentElement.innerHTML = "";
  }
}
