import { Controller } from '@hotwired/stimulus';
import Sortable from 'sortablejs';

export default class extends Controller {
  static targets = ['list'];
  static values = {
    inputSelector: String,
    childrenSelector: String,
    handle: String,
    pageId: String,
    rowId: String,
  };

  connect() {
    const scope = this;
    this.sortable = Sortable.create(this.listTarget, {
      handle: this.handleValue,
      direction: 'horizontal',
      onSort: () => scope.onSort(scope),
    });
  }

  onSort(scope) {
    const selector = scope.inputSelectorValue;
    const allItems = scope.listTarget.querySelectorAll(
      scope.childrenSelectorValue
    );

    const data = {};
    for (let i = 0; i < allItems.length; i++) {
      const item = allItems[i];
      data[item.querySelector(this.inputSelectorValue).dataset.columnId] = i;
      item.querySelector(selector).value = i;
    }
    fetch(
      `/backoffice/pages/${this.pageIdValue}/rows/${this.rowIdValue}/columns/sort`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ columns: data }),
      }
    );
  }
}
