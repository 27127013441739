import { Controller } from "@hotwired/stimulus";
import TomSelect from "tom-select";

export default class extends Controller {
  static targets = ["select"];
  static values = { options: Array };

  connect() {
    new TomSelect(this.selectTarget, {
      valueField: "id",
      searchField: "title",
      maxItems: 1,
      options: this.optionsValue,
      maxOptions: 99999999,
      render: {
        option: function (data, escape) {
          return (
            '<div class="flex flex-row">' +
            '<img class="image w-4 h-4 mr-2" src=' +
            escape(data.icon_url) +
            ">" +
            '<span class="title">' +
            escape(data.title) +
            "</span>" +
            "</div>"
          );
        },
        item: function (data, escape) {
          return (
            '<div><div class="flex flex-row">' +
            '<img class="image w-4 h-4 mr-2" src=' +
            escape(data.icon_url) +
            ">" +
            '<span class="title">' +
            escape(data.title) +
            "</span>" +
            "</div></div>"
          );
        },
      },
    });
  }
}
