import { computeEarliestSegStart, preventDefault } from "@fullcalendar/core";
import { Controller } from "@hotwired/stimulus";
import { searchPropEnd } from "windicss/utils";

export default class extends Controller {
  static targets = ["input", "preview", "button", "trash", "remove"];
  static values = {
    image: String,
  };

  connect() {
    const remove = this.removeTarget;
    const preview = this.previewTarget;
    const trashIcon = this.trashTarget;

    this.previewTarget.src = this.imageValue;

    const scope = this

    trashIcon.addEventListener("click", function(e){
      scope.setRemoveImageInput('true')

      e.preventDefault()
    });
  }

  chooseFile() {
    this.inputTarget.click();
  }

  setRemoveImageInput(value){
    const placeholder = `https://via.placeholder.com/${this.previewTarget.width}x${this.previewTarget.height}?text=My%20App%20Guru`;
    this.removeTarget.defaultValue = this.removeTarget.value = value
    this.previewTarget.src = placeholder;
  }

  fileChosen(event) {
    const target = this.previewTarget;
    this.fileToDataUrl(event, (src) => (target.src = src));
  }

  fileToDataUrl(event, callback) {
    if (!event.target.files.length) return;

    let file = event.target.files[0],
      reader = new FileReader();

    reader.readAsDataURL(file);
    reader.onload = (e) => callback(e.target.result);
  }
}
